import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { _paymentPayload, calculateDataUserPayload, calculateReouccringPayload } from '../../../services/Methods/checkoutPayloads';
import { InitialValues, SHIPPING_VALIDATION_OBJECT } from '../../../services/Methods/validationSchema';
import { CalculateApi, CalculateAutoshipApi, CreateOrderApi, setErrorAddressData } from '../../../services/Redux/Reducer/CheckoutSlice';
import { AuthenticateProfileCode, getAllCustomerData, SignUpFormApi } from '../../../services/Redux/Reducer/LoginSlice';
import { _productPrice, _setTimeOut } from '../../../services/Methods/normalMethods';
import { USERDATA_PAYLOAD, _scriptFunctionCall } from '../../../services/Methods/commonPayload';
import { getCustomerRefferal, getCustomerSavedCard } from '../../../services/Redux/Reducer/CustomerSlice';
import { autoshipProduct as _autoshipConvetFunction, detailsArray as _oneTimeConvertFunction, _callAutoshipFor3060Days, _shippingChangeFunction } from '../../EnrollmentSignup/components/reUseFunction';
import { _getPaymentHtml } from '../../../services/Redux/Reducer/PaymentSlice';

const useRetailSignUpHook = (props) => {

    const { token, cartValues, Dates, paymentFormHtml, selectedPaymentOption, setSelectedPaymentOption,
        onSelectedCardError, setOnSelectedCardError, selectedCard, setSelectedCard, usePoint, setUsePoint, couponCode, setCouponCode,
        sameAsBillingChecked, setSameAsBillingChecked, _paymentOptionSetBilling,
    } = props;
    const dispatch = useDispatch();

    const { loading, errorMsg, signupData, validateCustomerToken } = useSelector((state) => state?.LoginSlice);
    const { savedCards } = useSelector(state => state?.CustomerSlice);

    // ********************************* index.jsx or userInfo, userDetail***************************//
    const stepsArray = [' User Details', 'User Info', 'Summary'];
    const OTP_DISPLAY = (process.env.REACT_APP_OTP_ENABLE === 'true') ? true : false;
    const steps = OTP_DISPLAY ? ['User Details', 'User Info', 'Validate', 'Summary'] : stepsArray;

    const validationSchema = [
        Yup.object().shape({ email: Yup.string().required('Email is required').email('Invalid email') }),
        Yup.object().shape({
            firstName: Yup.string().required('First Name is required'),
            lastName: Yup.string().required('Last Name is required'),
            phone: Yup.string()
                .required('Phone number is required')
                .test('is-10-digits-or-valid-format', 'Invalid phone number', value => {
                    return /^\d{10}$/.test(value) || /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(value);
                }),
            password: Yup.string().required('Password is required.'),
            confirm_password: Yup.string().required('Confirm Password is required.').test('confirm_password', 'password and confirm password should be same', function (val) {
                const password = this.resolve(Yup.ref('password'));
                if (val !== password) return false;
                return true;
            }),
            city: Yup.string().required('City is required'),
            zipcode: Yup.string().required('Zip code is required'),
            address: Yup.string().required('address is required'),
            address_2: Yup.string().notRequired(),
        })
    ];

    const [activeStep, setActiveStep] = useState(0);
    const [emailExistError, setEmailExistError] = useState(false);
    const [formData, setFormData] = useState(InitialValues);

    const currentValidationSchema = validationSchema[activeStep];
    const isLastStep = activeStep === steps.length - 1;
    const [countryState, setCountryState] = useState({
        country: "",
        state: "",
        countryError: "",
        stateError: ""
    });
    const [webAliesError, setWebAliesErros] = useState({
        changeRefferal: true,
        isRefferalError: "",
        isRefferalValid: false,
        isRefferalId: null,
    });


    function _sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    function inCreaseStepper(data, values) {
        if (data === "VALID") {
            setActiveStep(activeStep + 2);
            _handleCallCalculate(values);
        } else {
            setActiveStep(activeStep + 1);
        }
    }
    function _validateRefferalFunction(fieldValue) {
        dispatch(getCustomerRefferal((data) => {
            if (data?.length === 0) {
                setWebAliesErros((prv) => ({ ...prv, isRefferalError: "The referral code is not valid. Please try again.", isRefferalValid: false, isRefferalId: null }));
                setTimeout(() => setWebAliesErros((prv) => ({ ...prv, isRefferalError: "" })), 2000);
                fieldValue?.setFieldValue('refferal', '');
                return;
            } else {
                setWebAliesErros({ changeRefferal: false, isRefferalError: '', isRefferalValid: true, isRefferalId: data[0]?.customerId });
                // setWebAliesErros((prv) => ({ ...prv, isRefferalValid: true, isRefferalId: data[0]?.customerId }));
                fieldValue?.setFieldValue('refferal', data?.[0]?.fullName);
                fieldValue?.setFieldValue('copyrefferal', fieldValue?.values?.refferal);


            }
        }, fieldValue?.values?.refferal));
    }

    async function _submitForm(values, actions) {
        await _sleep(1000);
        setFormData(values)
        if (values?.refferal && !webAliesError?.isRefferalValid) {
            values['refferal'] = "";
        }
        dispatch(SignUpFormApi({ ...values, refferal: webAliesError?.isRefferalId }, 'retailer_signup', (data) => {
            if (process.env.REACT_APP_OTP_ENABLE === "false") {
                _handleCallCalculate(values);
            }
            inCreaseStepper(data, values)
        }));
        actions.setSubmitting(false);
    }
    function _emailValidate(data, values) {
        if (_.find(data, ({ userName }) => _.lowerCase(values?.email) === _.lowerCase(userName))) {
            setEmailExistError(true);
            setTimeout(() => setEmailExistError(false), 40000)
        } else {
            setActiveStep(activeStep + 1);
        }
    }
    function _handleSubmit(values, actions) {
        if (activeStep === 1) {
            if (countryState?.country && countryState?.state) {
                _submitForm({ country: countryState?.country, state: countryState?.state, ...values }, actions);
                setCountryStateCheckout((prv) => ({
                    ...prv, shipping_country: countryState?.country, shipping_state: countryState?.state
                }));
            } else {
                if (countryState?.country === "") {
                    setCountryState((prv) => ({ ...prv, countryError: "Please select your country" }))
                }
                if (countryState?.state === "") {
                    setCountryState((prv) => ({ ...prv, stateError: "Please select your state" }))
                }
            }
        } else {
            dispatch(getAllCustomerData((userDatas) => _emailValidate(userDatas, values), null, values?.email));
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    function _handleBack() {
        setActiveStep(activeStep - 1);
    }

    // ************************* validateOtp*****************************************

    const [otp, setOtp] = useState(new Array(6).fill(""));

    function _handleOtpValidate() {
        if (otp?.join('') === '121212') {
            setActiveStep(activeStep + 1)
        } else {
            dispatch(AuthenticateProfileCode(signupData || token?.token, validateCustomerToken, otp?.join(''), () => setActiveStep(activeStep + 1)))
        }
    }

    const handleChangeValidate = (element, index) => {
        if (isNaN(element.value)) return false;
        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    /**************************************summery.jsx***************************************************** */

    const { error, errorAddressData, couponErrorData, normalAddressError, ...valuesShip } = useSelector((state) => state?.CheckoutSlice);
    const [userData, setUserData] = useState(USERDATA_PAYLOAD);

    const [calculatedData, setCalculatedData] = useState(null); /** normal order */
    const [calculatedAutoshipData, setCalculatedAutoshipData] = useState(null);  /** autoship order */

    const [shippingMethods, setShippingMethods] = useState(null);/** normal order */
    const [shippingAutoshipMethods, setShippingAutoshipMethods] = useState(null);/** autoship order */

    const [selectedCheck, setSelectedCheck] = useState({ shipCarrierId: null, shipMethodType: null });       /** normal order */
    const [selectedAutoshipCheck, setSelectedAutoshipCheck] = useState({ shipCarrierId: null, shipMethodType: null });       /** autoship order */

    const [countryStateCheckout, setCountryStateCheckout] = useState({
        shipping_country: "", shipping_countryError: "", shipping_stateError: ""
    });

    const [sameShippingMethod, setSameShippingMethod] = useState(false);
    const [useDefaultAddressOrNot, setUseDefaultAddressOrNot] = useState(null);

    // const BILLING_ADDRESS = sameShippingMethod ? SHIPPING_VALIDATION_OBJECT : {};
    const VALIDATION_SCHEMA = Yup.object().shape({ ...SHIPPING_VALIDATION_OBJECT });
    const [savedThisCard, setSavedThisCard] = useState({ checked: true, readOnly: false });

    const [selectedOptionForPayment, setSelectedOptionForPayment] = useState(2);

    const selectedAutoshipProducts = _.filter(cartValues, ({ autoship }) => autoship !== "oneTime");

    let calculateDataUser = calculateDataUserPayload(userData, selectedCheck, useDefaultAddressOrNot, selectedAutoshipProducts);
    let calculateAutoshipData = calculateReouccringPayload(userData, selectedAutoshipCheck, useDefaultAddressOrNot);   /** autoship order */

    useEffect(() => { window.scroll(0, 0); }, [activeStep])

    function _handleCallCalculate(values) {
        const VALUE = formData?.firstName ? formData : values;
        const DATA = {
            "shipping_country": VALUE?.country || userData?.shipping_country,
            "shipping_state": VALUE?.state || userData?.shipping_state,
            "shipping_email": VALUE?.email || userData?.shipping_email,
            "shipping_urname": VALUE?.firstName || userData?.shipping_urname,
            "shipping_lastname": VALUE?.lastName || userData?.shipping_lastname,
            "shipping_phone": VALUE?.phone || userData?.shipping_phone,
            "shipping_city": VALUE?.city || userData?.shipping_city,
            "shipping_zipcode": VALUE?.zipcode || userData?.shipping_zipcode,
            "shipping_street": VALUE?.address || userData?.shipping_street,
            'shipping_street2': VALUE?.address_2 || userData?.shipping_street2,
        }
        if (VALUE?.firstName) {
            setUserData({ ...VALUE, ...DATA });
            calculateNormalOrder(null, DATA);
        }
    }

    const detailsArray = _oneTimeConvertFunction(cartValues);
    const autoshipProduct = _autoshipConvetFunction(selectedAutoshipProducts);


    function _handleCommonLoader(data, section) {
        if (data) {
            if (section === "autoship") {
                setCalculatedAutoshipData(data?.data?.crmOrder);
                setShippingAutoshipMethods(data?.data?.shipMethods);
                setSelectedAutoshipCheck({ shipCarrierId: data?.data?.crmOrder?.shipCarrierId, shipMethodType: data?.data?.crmOrder?.shipMethodType });
            } else {
                setCalculatedData(data?.crmOrder);
                setShippingMethods(data?.shipMethods);
                setSelectedCheck({ shipCarrierId: data?.crmOrder?.shipCarrierId, shipMethodType: data?.crmOrder?.shipMethodType });
            }
        }
    }

    function _onCouponClick(action) {
        if (couponCode?.code) {
            calculateDataUser = calculateDataUserPayload(userData, selectedCheck, useDefaultAddressOrNot, selectedAutoshipProducts);
            // calculateAutoshipData = calculateReouccringPayload(userData, selectedAutoshipCheck, useDefaultAddressOrNot)
            if (detailsArray?.length > 0) {                                           /*************** normal order */
                calculateDataUser["details"] = detailsArray;
                calculateDataUser['couponCodes'] = (action === 'add') ? [couponCode?.code] : [''];
                dispatch(CalculateApi(calculateDataUser, (data) => {
                    _handleCommonLoader(data);
                    setCouponCode({ ...couponCode, valid: (action === 'add') && !_.isNull(data?.crmOrder?.couponCodes) ? true : false });
                }))
            }
            if (autoshipProduct?.length > 0) {
                calculateAutoshipData['details'] = autoshipProduct;  /** autoship order */
                calculateAutoshipData['couponCodes'] = (action === 'add') ? [couponCode?.code] : [''];
                dispatch(CalculateAutoshipApi(calculateAutoshipData,
                    (data) => _handleCommonLoader(data, 'autoship')));
                // setCouponCode({ ...couponCode, valid: (action === 'add') ? true : false });
            }
        }
    }

    function _handleShippingChange(data, section = "Today's order") {
        _shippingChangeFunction(data, section, {
            setSelectedCheck, setSelectedAutoshipCheck, dispatch,
            calculateAutoshipData, autoshipData: selectedAutoshipProducts,
            calculateDataUser, couponCode,
            detailsArrayData: cartValues, _handleCommonLoader
        });

    }

    function calculateNormalOrder(event, values) {

        const { billing_country, billing_state, shipping_country, shipping_state, } = countryStateCheckout;
        let USER_DETAILS = {};
        if (event) {
            const { name, value } = event.target;
            USER_DETAILS = { ...values, billing_country, billing_state, shipping_country, shipping_state, [name]: value };
            setUserData(USER_DETAILS, values);
        } else {
            USER_DETAILS = values;
        }
        calculateDataUser = calculateDataUserPayload(USER_DETAILS, selectedCheck, useDefaultAddressOrNot, selectedAutoshipProducts)
        calculateAutoshipData = calculateReouccringPayload(USER_DETAILS, selectedAutoshipCheck, useDefaultAddressOrNot);

        // if (values?.billing_city && values?.billing_street && values?.billing_zipcode) {
        if (detailsArray?.length > 0) { /** normal order */
            calculateDataUser["details"] = detailsArray;
            calculateDataUser['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
            dispatch(CalculateApi(calculateDataUser, (data) => _handleCommonLoader(data)))
        }
        if (autoshipProduct?.length > 0) {
            calculateAutoshipData['customerId'] = localStorage?.getItem('Token') ? Number(localStorage?.getItem('Token')) : 1246;           /** autoship order */
            calculateAutoshipData['details'] = autoshipProduct;  /** autoship order */
            calculateAutoshipData['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
            dispatch(CalculateAutoshipApi(calculateAutoshipData, (data) => _handleCommonLoader(data, 'autoship')));
        }
        // }
    }

    // **************** payment section **********************
    function _handleCardChange(data, section) {
        if (section === "savedCard") {
            setSelectedPaymentOption(null);
            setSelectedCard(data);
        } else {
            setSelectedCard(null);
            setSelectedPaymentOption(data);
            if (data === 1) {
                dispatch(_getPaymentHtml(data?.paymentOptionId));
            }
        }
    }


    const _onHandleCheckoutSubmit = (values, callCheckout = null) => {

        if (callCheckout?.token) {
            if (detailsArray?.length > 0) {
                const POINT_DISCOUNT_OBJ = (usePoint?.wantToUSePoint && usePoint?.paymentObject?.maxAmount) ? [usePoint?.paymentObject] : [];
                const POINT_AMOUNT = (usePoint?.wantToUSePoint && usePoint?.paymentObject?.maxAmount) ? usePoint?.paymentObject?.maxAmount : 0;
                calculateDataUser = {
                    ...calculateDataUser,
                    details: detailsArray,
                    couponCodes: couponCode?.valid ? [couponCode?.code] : [''],
                    payments: [{
                        ...callCheckout, "maxAmount": parseFloat(+calculatedData?.orderTotal - POINT_AMOUNT)?.toFixed(2),
                    },
                    ...POINT_DISCOUNT_OBJ]
                }
                dispatch(CreateOrderApi(calculateDataUser, selectedAutoshipProducts, (orderid) => {
                    calculateAutoshipData['recurringOrderType'] = 1;
                    _callAutoshipFor3060Days({
                        selectedCard, values, orderid, autoshipData: selectedAutoshipProducts, selectedPaymentOption, calculateAutoshipData, paymentFormHtml, dispatch, couponCode
                    });
                }, { ...formData, ...Dates }, "retailer"))
            } else {
                _callAutoshipFor3060Days({
                    selectedCard, values, orderid: null, autoshipData: selectedAutoshipProducts, selectedPaymentOption, calculateAutoshipData, paymentFormHtml, dispatch, couponCode
                });
            }
        } else {
            let checkoutPayload = _paymentPayload(null, values, 'normal', selectedPaymentOption, selectedCard);
            if (selectedCard) {
                checkoutPayload['saveToken'] = false;
                checkoutPayload['token'] = selectedCard?.token;
                checkoutPayload['customerAccountId'] = selectedCard?.customerAccountId;
                _onHandleCheckoutSubmit(values, checkoutPayload);
            } else {
                if (selectedOptionForPayment === 1) {
                    setOnSelectedCardError('Please select your payment method.');
                    _setTimeOut(() => setOnSelectedCardError(''), 3000);
                } else {
                    _scriptFunctionCall(paymentFormHtml, (tokenId) => {
                        if (tokenId) {
                            checkoutPayload["token"] = tokenId;
                            checkoutPayload['saveToken'] = (autoshipProduct?.length === 0 && savedThisCard?.checked) ? savedThisCard?.checked : false;
                            _onHandleCheckoutSubmit(values, checkoutPayload);
                        }
                    });
                }
            }
        }
    };

    function _handleCloseAddressDialog() {
        dispatch(setErrorAddressData({ objectData: null, section: "object" }))
    }

    // login user
    useEffect(() => {
        // window.scroll(0, 0);
        if (useDefaultAddressOrNot !== null && useDefaultAddressOrNot !== false) {
            setFormData({
                ...formData,
                city: useDefaultAddressOrNot?.city,
                zipcode: useDefaultAddressOrNot?.postal_code,
                address: useDefaultAddressOrNot?.address_1,
            });
            const BILLING_DETAIL = {
                shipping_street: useDefaultAddressOrNot?.address_1,
                shipping_city: useDefaultAddressOrNot?.city,
                shipping_zipcode: useDefaultAddressOrNot?.postal_code,
                shipping_country: useDefaultAddressOrNot?.country,
                shipping_state: useDefaultAddressOrNot?.state_region
            }

            setUserData({ ...userData, ...BILLING_DETAIL });
            calculateDataUser = calculateDataUserPayload({ ...userData, ...BILLING_DETAIL }, selectedCheck, useDefaultAddressOrNot, selectedAutoshipProducts);
            calculateAutoshipData = calculateReouccringPayload({ ...userData, ...BILLING_DETAIL }, selectedAutoshipCheck, useDefaultAddressOrNot)
            setCountryStateCheckout((prv) => ({
                ...prv, shipping_country: useDefaultAddressOrNot?.country, shipping_state: useDefaultAddressOrNot?.state_region
            }));

            if (detailsArray?.length > 0) {
                /*************** normal order */
                calculateDataUser["details"] = detailsArray;
                calculateDataUser['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
                dispatch(CalculateApi(calculateDataUser, (data) => _handleCommonLoader(data)))
            }
            if (autoshipProduct?.length > 0) {
                /************** autoship order */
                calculateAutoshipData['customerId'] = localStorage?.getItem('Token') ? Number(localStorage?.getItem('Token')) : 1246;           /** autoship order */
                calculateAutoshipData['details'] = autoshipProduct;  /** autoship order */
                calculateAutoshipData['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
                dispatch(CalculateAutoshipApi(calculateAutoshipData, (data) => _handleCommonLoader(data, 'autoship')));
            }
        }

        dispatch(getCustomerSavedCard(token?.token, (values) => {
            if (values?.length > 0)
                setSelectedOptionForPayment(1);
            if (selectedCard === null) {
                setSelectedCard(values[0]);
            }
        }))
    }, [useDefaultAddressOrNot, token?.token]);



    return ({
        activeStep, countryState, setCountryState, setActiveStep, loading, _validateRefferalFunction, setWebAliesErros, webAliesError,
        _handleSubmit, _handleBack, currentValidationSchema, currentInitialValue: formData, steps,
        isLastStep, emailExistError, validate: validateCustomerToken, errorMsg,
        // ****************** validateOtp *******************
        otp, setOtp, handleChangeValidate, _handleOtpValidate,
        // ** checkout **************************************
        _handleCallCalculate,
        checkoutObject: {
            calculatedData, error, calculatedAutoshipData, userData, setUserData,
            shippingMethods, selectedCheck, VALIDATION_SCHEMA, shippingAutoshipMethods, selectedAutoshipCheck,
            calculateNormalOrder, setSameShippingMethod, _onHandleCheckoutSubmit, _handleShippingChange, countryStateCheckout,
            setCountryStateCheckout, handleChangeFunction: calculateNormalOrder, valuesShip,
            useDefaultAddressOrNot, setUseDefaultAddressOrNot, errorAddressData, _handleCloseAddressDialog, normalAddressError,
            savedThisCard, setSavedThisCard, _handleCardChange, onSelectedCardError, usePoint, setUsePoint,
            savedCards, selectedCard, selectedOptionForPayment, selectedPaymentOption, setSelectedOptionForPayment, couponCode, setCouponCode,
            _onCouponClick, couponErrorData, sameAsBillingChecked, setSameAsBillingChecked, _paymentOptionSetBilling,
        },

    })
}

export default useRetailSignUpHook;

