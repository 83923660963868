import React, { useEffect } from 'react';
import { MainCheckOutStyle } from '../styles/MainCheckout.style';
import ButtonComponent from '../../../inputs/ButtonCompnent';
import { ErrorDisplay, storeAssigned, _deleteProductFromCart } from '../../../services/Methods/normalMethods';
import { Form, Formik } from 'formik';
import PaymentSection from './PaymentSection';
import AddressSection from './AddressSection';
import ShippingSection from './ShippingSection';
import _ from 'lodash';
import OrderSummery from './OrderSummery';
import LoaderComponent from '../../../Layout/components/Footer/components/Loader';
import SavedCard from './SavedCard';
import { CreateObject, OuterLoader } from '../../../services/Methods/checkoutPayloads';
import { _sameAsShippingCall } from '../../../services/Methods/commonPayload';
import PointCollection from '../../../Components/PointCollection';
import { ConnectedFocusError } from 'focus-formik-error'
import localStorageCall from '../../../services/Methods/localstorage.hook';
import CouponComponent from '../../../Components/CouponComponent';
import AddressOptions from './AddressOptions';

const MainCheckoutSection = (props) => {
    // userData
    const {
        setCartValues, VALIDATION_SCHEMA, subTotal, calculatedData, selectedCheck, userData, setUserData, error, countryCodes,
        countryState, shippingAutoshipMethods, selectedAutoshipCheck, setCountryState, _onHandleSubmit, _handleShippingChange,
        handleChangeFunction, shippingMethods, valuesShip, calculatedAutoshipData, fetchUserDataLoading, paymentsOptions,
        savedCards, _handleCardChange, selectedCard, setSelectedCard, token, normalAddressError, savedThisCard, setSavedThisCard, defaultValues,
        selectedPaymentOption, setSelectedPaymentOption, paymentFormHtml, selectedOptionForPayment, setSelectedOptionForPayment, alluserData,
        usePoint, setUsePoint, onSelectedCardError, checkValidUser, otpPopUp, otp, setOtpPopUp, OTPCSS, handleChangeValidate,
        _createMarchentProfile, _authenticateOtp, setDefaultValues, couponCode, setCouponCode, _onCouponClick, couponErrorData,
        _setUseDefaultAddressFunction, sameAsBillingChecked, setSameAsBillingChecked, _paymentOptionSetBilling, openDialog, setOpenDialog,
    } = props;

    const PARENTS_PRODUCT_CALCULATE = _.filter(calculatedData?.details, (value) => value?.parentItemId === null);
    const PARENTS_PRODUCT_AUTOSHIP = _.filter(calculatedAutoshipData?.details, (value) => value?.parentItemId === null);

    const NORMAL_PRODUCTS = _.map(PARENTS_PRODUCT_CALCULATE, (row) => {
        const values = CreateObject(row);
        return ({
            ...values, subProducts: _.map(_.filter(calculatedData?.details, (r) => r?.parentItemId === row?.itemId), (v) => CreateObject(v))
            , autoship: "oneTime"
        });
    });

    const AUTOSHIP_PRODUCTS = _.map(PARENTS_PRODUCT_AUTOSHIP, (row) => {
        const values = CreateObject(row);
        return ({
            ...values, subProducts: _.map(_.filter(calculatedAutoshipData?.details, (r) => r?.parentItemId === row?.itemId), (v) => CreateObject(v)),
            autoship: "autoship"
        })
    });


    return (
        <>
            <MainCheckOutStyle>
                {(fetchUserDataLoading || valuesShip?.isLoading) && <LoaderComponent />}
                <div className='checkout'>
                    <div className='checkout-main'>
                        <div className='container '>
                            <div className='checkout-left'>
                                <div className='form-info'>
                                    <h2>Customer Information</h2>
                                    <Formik
                                        enableReinitialize
                                        initialValues={{ ...userData, termscondition: false, sameShipping: false }}
                                        validationSchema={VALIDATION_SCHEMA}
                                        onSubmit={(values, actions) => {
                                            const { shipping_country, shipping_state } = countryState;
                                            const currentValues = {
                                                phone: values?.shipping_phone,
                                                email: defaultValues?.email || values?.shipping_email,
                                                firstName: defaultValues?.firstName || values?.shipping_urname,
                                                lastName: defaultValues?.lastName || values?.shipping_lastname,
                                                city: defaultValues?.city || values?.shipping_city,
                                                zipcode: defaultValues?.zipcode || values?.shipping_zipcode,
                                                address: defaultValues?.address || values?.shipping_street,
                                                webAlias: defaultValues?.webAlias,
                                                country: defaultValues?.country || values?.shipping_country,
                                                state: defaultValues?.state || values?.shipping_state,
                                                address_2: defaultValues?.address_2 || values?.shipping_street2,
                                            }
                                            setDefaultValues(currentValues);
                                            // if (checkValidUser) {
                                            //     _onHandleSubmit({ billing_country, billing_state, ...values });
                                            // } else {
                                            //     _createMarchentProfile(currentValues, () => {
                                            //         _onHandleSubmit({ billing_country, billing_state, ...values });
                                            //     });
                                            // }
                                            _onHandleSubmit({ ...values, shipping_country, shipping_state });
                                        }}
                                    >
                                        {({ setFieldValue, values, errors, handleSubmit, handleChange, handleBlur }) => {
                                            const FieldMethod = { values, setFieldValue, errors, handleBlur, handleChange, handleChangeFunction }
                                            return (
                                                <Form id="collect-form">
                                                    <ConnectedFocusError />
                                                    <div className='shipping-form'>
                                                        <div className='shipping_fields'>
                                                            {/* {error && <p className='wholesaler_error' style={{ color: "white" }}>ERROR: {error}</p>} */}
                                                            {error === 'Wholesalers must purchase a minimum of 25 items.' ? <p className='wholesaler_error' style={{ color: "white" }}>ERROR: {error}</p> : ''}

                                                            {/* <div className='wholesaler_error'></div> */}

                                                            <h3 className='checkout_heading_address'>Shipping Address</h3>
                                                            {(token?.token && alluserData?.emailAddress)
                                                                ?
                                                                <div className='mb-2 field-class'>
                                                                    <AddressOptions {...{
                                                                        sameAsBillingChecked, _paymentOptionSetBilling, openDialog, setOpenDialog, error,
                                                                        setDefaultValues, _setUseDefaultAddressFunction,
                                                                        alluserData, handleChangeFunction, userData: userData, VALIDATION_SCHEMA, setUserData,
                                                                        countryCodes, normalAddressError, countryState, setCountryState, NORMAL_PRODUCTS
                                                                    }} />
                                                                    {/* {error && <p style={{ color: "red" }}>{error}</p>} */}

                                                                    {normalAddressError && <p style={{ color: "red" }}>{normalAddressError}</p>}
                                                                </div>
                                                                :
                                                                <AddressSection section="shipping" {...{
                                                                    countryCodes, normalAddressError, countryState, setCountryState, ...FieldMethod, NORMAL_PRODUCTS
                                                                }} />
                                                            }
                                                        </div>
                                                        <label className='mb-2 field-class neweletter-subscrib'>
                                                            <input type="checkbox" /> Subscribe to our newsletter
                                                        </label>
                                                        <div className='mb-2 field-class'>
                                                            <textarea type="text" placeholder='Order Notes (optional)' />
                                                        </div>
                                                        <div className='shipping_section' style={{ display: "flex", gap: "30px" }}>
                                                            {shippingMethods?.length > 0 &&
                                                                <ShippingSection section="Today's order"  {...{
                                                                    selectedCheck, shippingMethods, _handleShippingChange
                                                                }} />}
                                                            {shippingAutoshipMethods?.length > 0 &&
                                                                <ShippingSection section="autoship" {...{
                                                                    selectedCheck: selectedAutoshipCheck, shippingMethods: shippingAutoshipMethods, _handleShippingChange
                                                                }} />}
                                                        </div>
                                                        {(calculatedData?.pointAccounts?.length > 0) &&
                                                            <PointCollection {...{ calculatedData, usePoint, setUsePoint }} />
                                                        }
                                                        <CouponComponent {...{ couponCode, setCouponCode, _onCouponClick, couponErrorData }} />
                                                        <div>
                                                            <h3 className='checkout_heading_address' >Payment Info</h3>
                                                            {token?.token &&
                                                                <SavedCard {...{
                                                                    setSameAsBillingChecked,
                                                                    selectedOptionForPayment, setSelectedOptionForPayment, selectedPaymentOption,
                                                                    paymentsOptions, _handleCardChange, selectedCard, setSelectedCard, savedCards
                                                                }} />
                                                            }
                                                            {(selectedOptionForPayment === 2) &&
                                                                <>
                                                                    {(selectedPaymentOption === 1) &&
                                                                        <div className="mt-4 d-flex sameAsShipping-checkbox field-class neweletter-subscrib ">
                                                                            <input
                                                                                type="checkbox"
                                                                                className=''
                                                                                style={{ top: '-2px' }}
                                                                                name="sameAsBilling"
                                                                                checked={sameAsBillingChecked}
                                                                                onChange={(e) => {
                                                                                    if (e?.target?.checked) {
                                                                                        setSameAsBillingChecked(e?.target?.checked);
                                                                                        _paymentOptionSetBilling(userData);

                                                                                    } else {
                                                                                        setSameAsBillingChecked(e?.target?.checked);
                                                                                        window.paymentOption?.clearBilling();
                                                                                    }
                                                                                }} />
                                                                            <p className='' style={{ fontSize: '20px', margin: 0 }}>Same as shipping</p>
                                                                        </div>
                                                                    }
                                                                    <PaymentSection {...{ minHeight: "630px", paymentFormHtml, savedThisCard, setSavedThisCard, ...FieldMethod }} />
                                                                </>
                                                            }
                                                        </div>
                                                        <div>
                                                            <div className="mb-3 field-class shipping condition-checkout">
                                                                <input type="checkbox"
                                                                    checked={values?.termscondition || false}
                                                                    onChange={(e) => { setFieldValue('termscondition', e.target.checked); }}
                                                                    id="Terms-Condition"
                                                                    name="termscondition"

                                                                /><p style={{ fontSize: "24px" }}>I have read and agree to the
                                                                    <a style={{ color: "rgb(42, 83, 63)", }} href={`/${storeAssigned()}/terms-and-conditions`}
                                                                        target="_blank"> website terms and conditions*</a></p>
                                                            </div>
                                                            {/* <ErrorMessage name='termscondition' render={(msg) => <p className='error'>{msg}</p>} /> */}
                                                            {errors?.termscondition && <p style={{ color: 'red' }}>Please Accept Terms and conditions</p>}
                                                        </div>
                                                        <div className="mb-3 field-class shipping promotions-checkbox">
                                                            <input type="checkbox"
                                                                id="Terms-Condition1"
                                                                name="termscondition1"
                                                            />I want to receive updates about products and promotions.
                                                        </div>
                                                        <div className='place-order'>
                                                            <ButtonComponent onClick={() => window.location.assign(`/${storeAssigned()}/shop`)}>Continue Shopping</ButtonComponent>
                                                            <ButtonComponent type='submit' disabled={(NORMAL_PRODUCTS?.length === 0 && AUTOSHIP_PRODUCTS?.length === 0)}>Place Order</ButtonComponent>
                                                        </div>
                                                        {error && <p style={{ color: "red" }}>{error}</p>}

                                                        {onSelectedCardError && <ErrorDisplay message={onSelectedCardError} />}
                                                    </div>
                                                    {/* <DialogComponent opend={otpPopUp} handleClose={() => setOtpPopUp(false)} title="" classFor="otpSection_data">
                                                        <OTPCSS>
                                                            <OneTimePass {...{ otp, handleChangeValidate }} />
                                                            <DialogActions className='button_action'>
                                                                <ButtonComponent classes="order_submit" onClick={() => {
                                                                    _authenticateOtp(() => handleSubmit())
                                                                }}>Submit</ButtonComponent>
                                                            </DialogActions>
                                                        </OTPCSS>
                                                    </DialogComponent> */}
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                    {localStorageCall().getSingleItem('error-payment') && <p className='error'>{localStorageCall().getSingleItem('error-payment')}</p>}
                                </div>
                            </div>
                            <div className='mainorder_summery'>
                                <div className="sticky-wrap">

                                    {(NORMAL_PRODUCTS?.length > 0)
                                        ?
                                        <OrderSummery calculate={calculatedData} cartData={NORMAL_PRODUCTS} setCartValues={setCartValues} section="Today's" subTotal={subTotal?.normalSubTotal} usePoint={usePoint} />
                                        :
                                        <OuterLoader section="Normal" />

                                    }
                                    {(AUTOSHIP_PRODUCTS?.length > 0)
                                        ?
                                        <OrderSummery calculate={calculatedAutoshipData} cartData={AUTOSHIP_PRODUCTS} setCartValues={setCartValues} section="Autoship" subTotal={subTotal?.autoshipSubTotal} usePoint={usePoint} />
                                        :
                                        (NORMAL_PRODUCTS?.length > 0) ? <></> : <OuterLoader section="Autoship" />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MainCheckOutStyle>
        </>
    )
}

export default MainCheckoutSection;