import React from 'react'
import styled from 'styled-components'

const ShippingStyle = styled.div`
 .row {
        display: flex;
        padding: 169px;
    }
        h1 {
            color: #2a533f !important;
        }
    p {
        font-size: 16px;
        color: #39332e;
        line-height: 1.66em;
    }
    .shipping-section {
    background: grey;
}
.shipping-section .container {
    background: #fff;
}
.shipping-section .container .row {
    padding: 189px 35px;
}
.terms-condition h1 {
    padding: 0 0 20px;
    font-family: "Playfair Display";
    font-size: 45px;
    font-weight: 700;
    line-height: 40px;
}
.shipping-section h3 {
    font-size: 32px;
    font-family: 'Helvetica Neue LT Std';
    font-weight: 400;
    padding: 20px 0 3px;
}
.shipping-section p {
    text-align: justify;
}
@media(max-width:991px){
.shipping-section .container .row {
    padding: 155px 0;
}
.terms-condition h1 {
    font-size: 22px;
    line-height: normal;
}
.shipping-section h3 {
    font-size: 20px;
    padding: 5px 0px 3px;
}
}

@media(max-width:568px){
.shipping-section .container .row {
    padding: 95px 0;
}
}
`

const Shipping = () => {
    return (
        <ShippingStyle>
            <div className='shipping-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='policy'>
                            <h1> Shipping Policy for Abeytu´&reg; Naturals</h1>
                        </div>

                        <div className='shipping-fees'>
                            <h3>1. Shipping Fees:</h3>
                            <p>
                                Abeytu´&reg; Naturals is dedicated to providing our customers with a seamless shopping experience, which includes reliable and efficient shipping services. Please note that for all orders, customers will be responsible for paying shipping fees. The shipping costs will be calculated based on the destination, shipping method, and the weight of the products in your order. You will have the opportunity to review and confirm these costs during the checkout process.
                            </p>

                        </div>

                        <div className='shipping-methods'>
                            <h3>2. Shipping Methods:</h3>
                            <p>
                                We offer several shipping methods to cater to your preferences and delivery time requirements. These options may vary depending on your location and the type of products you order. Our standard shipping methods include:

                                - Standard Shipping: This is our default shipping option and provides a cost-effective and reasonably quick delivery service.
                                - Express Shipping: For those who need their orders delivered more urgently, we offer express shipping services. Additional fees will apply for this option.
                                - International Shipping: We are pleased to offer international shipping for customers outside of our domestic market. Please note that international shipping may come with additional shipping fees and considerations, particularly for CBD-based products and is handled by our partner Navi Organics.

                            </p>
                        </div>

                        <div className='international-payments'>
                            <h3>3. Customs and International Shipments:</h3>
                            <p> For international shipments, particularly those containing CBD-based products, please be aware that customs regulations and restrictions vary by country. It is the responsibility of the customer to be informed about and comply with the customs regulations in their destination country. Abeytu´&reg; Naturals is not liable for any products that are confiscated, delayed, or returned by customs in your country.
                                If your order is seized or confiscated by customs authorities in your country, Abeytu´&reg; Naturals is not responsible for any costs associated with these actions, including any fines, taxes, or import duties. Please ensure that you are aware of your country's laws and regulations regarding CBD-based products before placing an order. If you have any concerns about customs regulations in your area, we recommend contacting your local customs office for guidance before placing an order.
                            </p>
                        </div>
                        <div className='shiiping-timelines'>
                            <h3> 4. Shipping Timelines:</h3>
                            <p> We are committed to processing and shipping your orders in a timely manner. However, please note that shipping times may vary based on your location and the chosen shipping method. Estimated delivery times will be provided during the checkout process and are dependent on the carrier's performance.
                            </p>
                        </div>

                        <div className='orders'>
                            <h3>5. Tracking Your Order:</h3>
                            <p>Abeytu´&reg; Naturals provides order tracking services for your convenience. Once your order has been processed and shipped, you will receive a tracking number via email. You can use this tracking number to monitor the status and location of your package during transit.
                            </p>
                        </div>

                        <div className='contact-info'>
                            <h3>6. Contact Information:</h3>

                            <p>If you have any questions or require further information regarding our shipping policy, please do not hesitate to contact our Customer Service team. We are here to assist you and ensure a smooth shopping experience with Abeytu´&reg; Naturals.
                            </p>
                        </div>

                        <div className='email-section'>
                            <h3>Contact Information:</h3>
                            <p> - Email: info@abeytunaturals.com</p>
                        </div>

                        <p>you for shopping with Abeytu´&reg; Naturals. We value your business and are committed to providing high-quality products and services.
                        </p>
                    </div>
                </div>
            </div>
        </ShippingStyle>
    )
}

export default Shipping;