import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { _productPrice, storeAssigned, _setTimeOut } from '../../../services/Methods/normalMethods';
import { InitialValues, SHIPPING_VALIDATION_OBJECT } from '../../../services/Methods/validationSchema';
import { CalculateApi, CalculateAutoshipApi, CreateAutoshipOrderApi, CreateOrderApi } from '../../../services/Redux/Reducer/CheckoutSlice';
import { _paymentPayload, calculateDataUserPayload, calculateReouccringPayload, _checkoutAddressFormat, _priceTypeForCalculate } from '../../../services/Methods/checkoutPayloads';
import { USERDATA_PAYLOAD, _scriptFunctionCall } from '../../../services/Methods/commonPayload';
import moment from 'moment';
import { _getPaymentHtml } from '../../../services/Redux/Reducer/PaymentSlice';
import { getCustomerSavedCard } from '../../../services/Redux/Reducer/CustomerSlice';

const useCheckoutHook = (props) => {
    const {
        cartValues, alluserData, token, navigate, Dates, paymentFormHtml, setOnSelectedCardError,
        selectedPaymentOption, usePoint, setSelectedPaymentOption, selectedCard, setSelectedCard,
        couponCode, setCouponCode
    } = props;
    const dispatch = useDispatch();
    const { error, errorAddressData, normalAddressError, setErrorAddressData, couponErrorData, ...valuesShip } = useSelector((state) => state?.CheckoutSlice);
    const { loading: fetchUserDataLoading } = useSelector(state => state?.LoginSlice);
    const { savedCards } = useSelector(state => state?.CustomerSlice);

    const [useDefaultAddressOrNot, setUseDefaultAddressOrNot] = useState(null);

    const [defaultValues, setDefaultValues] = useState(InitialValues);
    const [userData, setUserData] = useState(USERDATA_PAYLOAD);
    const [calculatedData, setCalculatedData] = useState(null);  /** normal order */
    const [calculatedAutoshipData, setCalculatedAutoshipData] = useState(null);  /** autoship order */

    const [shippingMethods, setShippingMethods] = useState(null);/** normal order */
    const [shippingAutoshipMethods, setShippingAutoshipMethods] = useState(null);/** autoship order */

    const [selectedCheck, setSelectedCheck] = useState({ shipCarrierId: null, shipMethodType: null });       /** normal order */
    const [selectedAutoshipCheck, setSelectedAutoshipCheck] = useState({ shipCarrierId: null, shipMethodType: null });       /** autoship order */
    const [selectedOptionForPayment, setSelectedOptionForPayment] = useState(2);

    const [countryState, setCountryState] = useState({
        shipping_country: "", shipping_state: "", shipping_countryError: "", shipping_stateError: ""
    });

    const [subTotal, setSubtotal] = useState({ normalSubTotal: 0, autoshipSubTotal: 0 });
    const [savedThisCard, setSavedThisCard] = useState({ checked: true, readOnly: false });
    const [openDialog, setOpenDialog] = useState(null);

    const VALIDATION_SCHEMA = Yup.object().shape({ ...SHIPPING_VALIDATION_OBJECT });

    const selectedAutoshipProducts = _.filter(cartValues, ({ autoship }) => autoship !== "oneTime");

    let calculteDataUser = calculateDataUserPayload(userData, selectedCheck, useDefaultAddressOrNot, selectedAutoshipProducts);          /** normal order */
    let calculateAutoshipData = calculateReouccringPayload(userData, selectedAutoshipCheck, useDefaultAddressOrNot);   /** autoship order */

    const detailsArray = _.map(cartValues, (item, index) => ({          /** normal order */
        parentItemId: 0,
        "parentLineNumber": 0,
        orderLineNumber: index + 1,
        itemId: item?.id,
        itemCode: item?.itemCode,
        quantity: item?.quantity
    }));

    const autoshipProduct = _.map(selectedAutoshipProducts, (item, index) => ({     /** autoship order */
        parentItemId: 0,
        "parentLineNumber": 0,
        orderLineNumber: index + 1,
        itemId: item?.id,
        itemCode: item?.itemCode,
        quantity: item?.quantity,

    }));

    function _handleCommonLoader(data, section) {
        if (data) {
            // setCommonLoader(false);
            if (section === "autoship") {
                setCalculatedAutoshipData(data?.data?.crmOrder);
                setShippingAutoshipMethods(data?.data?.shipMethods);
                setSelectedAutoshipCheck({ shipCarrierId: data?.data?.crmOrder?.shipCarrierId, shipMethodType: data?.data?.crmOrder?.shipMethodType });
            } else {
                setCalculatedData(data?.crmOrder);
                setShippingMethods(data?.shipMethods);
                setSelectedCheck({ shipCarrierId: data?.crmOrder?.shipCarrierId, shipMethodType: data?.crmOrder?.shipMethodType });
            }
        }
    }

    function _setUseDefaultAddressFunction(data, useDefaultAddressOrNot, userData) {
        const CHANGE_COUNTRY = userData?.shipping_country ? {
            shipping_country: userData?.shipping_country, shipping_state: userData?.shipping_state
        } : countryState;
        const BILLING_DETAIL = _checkoutAddressFormat(data, userData, useDefaultAddressOrNot, CHANGE_COUNTRY);
        setUserData(BILLING_DETAIL);

        const shippingValue = { shipCarrierId: null, shipMethodType: null };
        const shippingAutoship = { shipCarrierId: null, shipMethodType: null }

        calculteDataUser = calculateDataUserPayload(BILLING_DETAIL, shippingValue, useDefaultAddressOrNot, selectedAutoshipProducts);
        calculateAutoshipData = calculateReouccringPayload(BILLING_DETAIL, shippingAutoship, useDefaultAddressOrNot)
        setCountryState((prv) => ({
            shipping_country: useDefaultAddressOrNot?.country || userData?.shipping_country || prv?.shipping_country || data?.countryCode,
            shipping_state: useDefaultAddressOrNot?.state_region || userData?.shipping_state || prv?.shipping_state || data?.shippingAddress?.regionProvState
        }));

        if (detailsArray?.length > 0) { /*************** normal order */
            calculteDataUser["details"] = detailsArray;
            calculteDataUser['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
            calculteDataUser['priceType'] = _priceTypeForCalculate(selectedAutoshipProducts);
            dispatch(CalculateApi(calculteDataUser, (data) => _handleCommonLoader(data)))
        }
        if (autoshipProduct?.length > 0) { /************** autoship order */
            calculateAutoshipData['customerId'] = localStorage?.getItem('Token') ? Number(localStorage?.getItem('Token')) : 1246; /** autoship order */
            calculateAutoshipData['details'] = autoshipProduct; /** autoship order */
            calculateAutoshipData['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
            calculateAutoshipData['priceType'] = _priceTypeForCalculate(selectedAutoshipProducts);
            dispatch(CalculateAutoshipApi(calculateAutoshipData, (data) => _handleCommonLoader(data, 'autoship')));
        }
        dispatch(getCustomerSavedCard(token?.token, (values) => {
            if (values?.length > 0)
                setSelectedOptionForPayment(1);
            if (selectedCard === null) {
                setSelectedCard(values[0]);
            }
        }));
    }

    useEffect(() => {
        window.scroll(0, 0);
        if (token?.token && alluserData?.emailAddress) {
            const data = alluserData;
            setDefaultValues((prv) => ({
                ...prv,
                email: data?.emailAddress,
                firstName: data?.firstName,
                lastName: data?.lastName, phone: "",
                city: data?.shippingAddress?.city,
                zipcode: data?.shippingAddress?.postalCode,
                address: data?.billingAddress?.address1 || data?.shippingAddress?.address1,
                webAlias: data?.webAlias,
                country: data?.billingAddress?.countryCode || data?.shippingAddress?.countryCode || data?.countryCode,
                state: data?.billingAddress?.regionProvState || data?.shippingAddress?.regionProvState || data?.publicProfile?.regionProvState,
                phone: data?.publicProfile?.phone || data?.phoneNumbers?.cellPhone,
                address_2: data?.billingAddress?.address2 || data?.shippingAddress?.address2,
            }))
            _setUseDefaultAddressFunction(data, useDefaultAddressOrNot, userData)
        }
    }, [alluserData?.emailAddress, useDefaultAddressOrNot]);


    function _onCouponClick(action) {
        if (detailsArray?.length > 0) { /*************** normal order */
            calculteDataUser["details"] = detailsArray;
            calculteDataUser['couponCodes'] = (action === 'add') ? [couponCode?.code] : [''];
            calculteDataUser['priceType'] = _priceTypeForCalculate(selectedAutoshipProducts);
            dispatch(CalculateApi(calculteDataUser, (data) => {
                _handleCommonLoader(data);
                setCouponCode({ ...couponCode, valid: (action === 'add') && !_.isNull(data?.crmOrder?.couponCodes) ? true : false });
            }))
        }
        if (autoshipProduct?.length > 0) {
            /************** autoship order */
            calculateAutoshipData['customerId'] = localStorage?.getItem('Token') ? Number(localStorage?.getItem('Token')) : 1246; /** autoship order */
            calculateAutoshipData['details'] = autoshipProduct; /** autoship order */
            calculateAutoshipData['couponCodes'] = (action === 'add') ? [couponCode?.code] : [''];
            calculateAutoshipData['priceType'] = _priceTypeForCalculate(selectedAutoshipProducts);
            dispatch(CalculateAutoshipApi(calculateAutoshipData, (data) => _handleCommonLoader(data, 'autoship')));
        }
    }

    useEffect(() => {
        if (cartValues?.length === 0) {
            window.location.assign(`/${storeAssigned()}/shop`)
        } else {
            const NORMAL_SUB_TOTAL = _.sum(_.map(_.filter(cartValues, { autoship: "oneTime" }), ({ quantity, prices, autoship, isEligibleForAutoOrder }) => quantity * _productPrice(prices, autoship, isEligibleForAutoOrder)));
            const AUTOSHIP_SUB_TOTAL = _.sum(_.map(_.filter(cartValues, ({ autoship }) => (autoship !== "oneTime")), ({ quantity, prices, autoship, isEligibleForAutoOrder }) => quantity * _productPrice(prices, autoship, isEligibleForAutoOrder)));
            setSubtotal({
                normalSubTotal: NORMAL_SUB_TOTAL || 0,
                autoshipSubTotal: AUTOSHIP_SUB_TOTAL || 0
            })
            const AutshipProducts = _.filter(cartValues, ({ autoship }) => autoship !== "oneTime");
            if (AutshipProducts?.length > 0) {
                setSavedThisCard((prv) => ({ ...prv, readOnly: true }))
            }
        }
    }, [cartValues]);

    function _handleShippingChange(data, section = "Today's order") {
        const SELECTED_SHIPPING = {
            shipCarrierId: data?.shipCarrierId,
            shipMethodType: data?.shipMethodType
        };
        if (section === "Today's order") {
            setSelectedCheck(SELECTED_SHIPPING);
            calculteDataUser = { ...calculteDataUser, "details": detailsArray };
            calculteDataUser['shipMethod'] = _.assign(calculteDataUser?.shipMethod, SELECTED_SHIPPING);
            calculteDataUser['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
            calculteDataUser['priceType'] = _priceTypeForCalculate(selectedAutoshipProducts);
            dispatch(CalculateApi(calculteDataUser, (data) => _handleCommonLoader(data)))
        } else {
            setSelectedAutoshipCheck(SELECTED_SHIPPING);
            calculateAutoshipData["details"] = autoshipProduct;
            calculateAutoshipData['shipMethod'] = _.assign(calculateAutoshipData?.shipMethod, SELECTED_SHIPPING);
            calculateAutoshipData['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
            calculateAutoshipData['priceType'] = _priceTypeForCalculate(selectedAutoshipProducts);
            dispatch(CalculateAutoshipApi(calculateAutoshipData, (data) => _handleCommonLoader(data, 'autoship')))
        }

    }

    function _handleCardChange(data, section) {
        if (section === "savedCard") {
            setSelectedPaymentOption(null);
            setSelectedCard(data);
        } else {
            setSelectedCard(null);
            setSelectedPaymentOption(data);
            if (data === 1) {
                dispatch(_getPaymentHtml(data?.paymentOptionId));
            }
        }
    }

    function calculateNormalOrder(event, values) {
        let USER_DETAILS = {};
        if (event) {
            const { name, value } = event.target;
            USER_DETAILS = { ...values, [name]: value };
            setUserData(USER_DETAILS);
        } else {
            USER_DETAILS = values;
        }
        calculteDataUser = calculateDataUserPayload(USER_DETAILS, selectedCheck, useDefaultAddressOrNot, selectedAutoshipProducts)
        calculateAutoshipData = calculateReouccringPayload(USER_DETAILS, selectedAutoshipCheck, useDefaultAddressOrNot);

        if (values?.shipping_city && values?.shipping_street && values?.shipping_zipcode) {
            if (detailsArray?.length > 0) { /** normal order */
                calculteDataUser = { ...calculteDataUser, "details": detailsArray, 'couponCodes': couponCode?.valid ? [couponCode?.code] : [''] };
                calculteDataUser['priceType'] = _priceTypeForCalculate(selectedAutoshipProducts);
                dispatch(CalculateApi(calculteDataUser, (data) => _handleCommonLoader(data)))
            }
            if (autoshipProduct?.length > 0) {
                calculateAutoshipData['customerId'] = localStorage?.getItem('Token') ? Number(localStorage?.getItem('Token')) : 1246;           /** autoship order */
                calculateAutoshipData['details'] = autoshipProduct;  /** autoship order */
                calculateAutoshipData['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
                calculateAutoshipData['priceType'] = _priceTypeForCalculate(selectedAutoshipProducts);
                dispatch(CalculateAutoshipApi(calculateAutoshipData, (data) => _handleCommonLoader(data, 'autoship')));
            }
        }
    }

    // add a day
    function callBackAutoshipFunction(AutoshipProduct, values, orderid, sucess = false, callBack) {
        if (AutoshipProduct?.length > 0) {

            let autoshipCartPayload = _paymentPayload(null, values, 'autoship', selectedPaymentOption, selectedCard);
            var date = new Date();
            date.setDate(date.getDate() + (!sucess ? 30 : 60))
            calculateAutoshipData['startDate'] = moment(date).utc().format();
            calculateAutoshipData['frequencyType'] = !sucess ? 1 : 7;
            calculateAutoshipData['priceType'] = _priceTypeForCalculate(selectedAutoshipProducts);

            if (autoshipProduct?.length > 0) {
                calculateAutoshipData['customerId'] = localStorage?.getItem('Token') ? Number(localStorage?.getItem('Token')) : 1246;
                calculateAutoshipData['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
                calculateAutoshipData["details"] = _.map(_.reject(AutoshipProduct, { autoship: "oneTime" }), (item, index) => ({     /** autoship order */
                    parentItemId: 0,
                    "parentLineNumber": 0,
                    orderLineNumber: index + 1,
                    itemId: item?.id,
                    itemCode: item?.itemCode,
                    quantity: item?.quantity,
                }));
            }

            if (selectedCard) {
                autoshipCartPayload['token'] = selectedCard?.token;
                autoshipCartPayload['recurringPaymentActionType'] = 2;

                autoshipCartPayload['customerAccountId'] = selectedCard?.customerAccountId;
                calculateAutoshipData['payments'] = [{ ...autoshipCartPayload, "maxAmount": calculatedAutoshipData?.orderTotal, }];
                dispatch(CreateAutoshipOrderApi(calculateAutoshipData, navigate, orderid, callBack));

            } else {
                _scriptFunctionCall(paymentFormHtml, (tokenId) => {
                    if (tokenId) {
                        autoshipCartPayload["token"] = tokenId;
                        autoshipCartPayload['recurringPaymentActionType'] = 1;
                        calculateAutoshipData['payments'] = [{ ...autoshipCartPayload, "maxAmount": calculatedAutoshipData?.orderTotal, }];
                        dispatch(CreateAutoshipOrderApi(calculateAutoshipData, navigate, orderid, callBack));
                    }
                });

            }
        }
    }

    function callbackAutoshipOrder(values, orderid) {
        const THIRTY_DAYS = _.filter(cartValues, { autoship: "30Days" });
        const SIXTY_DAYS = _.filter(cartValues, { autoship: "60Days" });
        if (THIRTY_DAYS?.length > 0 && SIXTY_DAYS?.length > 0) {
            callBackAutoshipFunction(THIRTY_DAYS, values, orderid, false, (res) => {
                if (SIXTY_DAYS?.length > 0 && res?.data) {
                    callBackAutoshipFunction(SIXTY_DAYS, values, orderid, true);
                }
            });
        } else if (THIRTY_DAYS?.length > 0) {
            callBackAutoshipFunction(THIRTY_DAYS, values, orderid, false);
        } else if (SIXTY_DAYS?.length > 0) {
            callBackAutoshipFunction(SIXTY_DAYS, values, orderid, true);
        }
    }

    // **************** payment section **********************
    const _onHandleSubmit = async (values, simplecheckoutPayload = null) => {
        try {
            if (simplecheckoutPayload?.token) {
                if (countryState?.shipping_state) {
                    if (detailsArray?.length > 0) {
                        const POINT_DISCOUNT = (usePoint?.wantToUSePoint && usePoint?.paymentObject?.maxAmount) ? [usePoint?.paymentObject] : [];
                        const POINT_AMOUNT = (usePoint?.wantToUSePoint && usePoint?.paymentObject?.maxAmount) ? usePoint?.paymentObject?.maxAmount : 0;

                        calculteDataUser["details"] = detailsArray;
                        calculteDataUser['couponCodes'] = couponCode?.valid ? [couponCode?.code] : [''];
                        calculteDataUser['priceType'] = _priceTypeForCalculate(selectedAutoshipProducts);
                        calculteDataUser['payments'] = [{
                            ...simplecheckoutPayload,
                            "maxAmount": parseFloat(+calculatedData?.orderTotal - POINT_AMOUNT)?.toFixed(2),
                        }, ...POINT_DISCOUNT];

                        dispatch(CreateOrderApi(calculteDataUser, autoshipProduct, (orderid) => callbackAutoshipOrder(values, orderid), { ...defaultValues, ...Dates }, 'retailer'))
                    }
                }
                else {
                    callbackAutoshipOrder(values, null)
                }
            } else {
                let checkoutPayload = _paymentPayload(null, values, 'normal', selectedPaymentOption, selectedCard);
                if (selectedCard) {
                    checkoutPayload['saveToken'] = false;
                    checkoutPayload['token'] = selectedCard?.token;
                    checkoutPayload['customerAccountId'] = selectedCard?.customerAccountId;
                    _onHandleSubmit(values, checkoutPayload);
                } else {
                    if (selectedOptionForPayment === 1) {
                        setOnSelectedCardError('Please select your payment method.');
                        _setTimeOut(() => setOnSelectedCardError(''), 3000);
                    } else {
                        _scriptFunctionCall(paymentFormHtml, (tokenId) => {
                            checkoutPayload["token"] = tokenId;
                            checkoutPayload['saveToken'] = (autoshipProduct?.length === 0 && savedThisCard?.checked) ? savedThisCard?.checked : false;
                            _onHandleSubmit(values, checkoutPayload);
                        });
                    }
                }
            }
        } catch (err) {
            console.log(err, 'err')
        }
    };

    return ({
        VALIDATION_SCHEMA, savedCards, _handleCardChange, selectedCard, openDialog, setOpenDialog,
        _onHandleSubmit, errorAddressData, couponErrorData, setUseDefaultAddressOrNot, useDefaultAddressOrNot, normalAddressError,
        calculatedData, calculatedAutoshipData, subTotal, error, userData, defaultValues, setDefaultValues,
        _handleShippingChange, handleChangeFunction: calculateNormalOrder,
        countryState, setCountryState, shippingMethods, selectedCheck, shippingAutoshipMethods,
        selectedAutoshipCheck, valuesShip, fetchUserDataLoading, _onCouponClick,
        savedThisCard, setSavedThisCard, setUserData, selectedOptionForPayment, setSelectedOptionForPayment, setErrorAddressData,
        _setUseDefaultAddressFunction
    });

}

export default useCheckoutHook;