import { CircularProgress } from '@mui/material';
import _ from 'lodash';
import { FloatValues, storeAssigned, _productPrice } from '../../../services/Methods/normalMethods';
import { HomeFirstSectionCss } from '../style/HomePage.style';

const HomeFirstSection = (props) => {
    let CUSTOMER_TYPE = localStorage?.getItem('customerTypeDescr') ? JSON.parse(localStorage?.getItem('customerTypeDescr')) : null;
    const { ProductsDetail } = props;
    const Unique_SECTION = [
        { image: require('../assets/madeinusa.png'), para: "Our premium quality products are manufactured in Utah, USA." },
        { image: require('../assets/antimicrobial.png'), para: "Antimicrobial Formulation" },
        { image: require('../assets/petentPending.png'), para: "Patent pending formulations" },
        { image: require('../assets/AwardWining.png'), para: "Most innovated beauty product featured in Elle Magazine." },
        { image: require('../assets/customerFocus.png'), para: "Every customer is a member of our health warrior team." },
    ];
    const FIRST_THREE_PRODUCT = _.map([1013, 1096, 1007], (row) => {
        const FIND_PRODUCT = _.find(ProductsDetail, { itemId: +row });
        if (FIND_PRODUCT) {
            return ({
                id: FIND_PRODUCT?.itemId, image: FIND_PRODUCT?.mediumImage, heading: FIND_PRODUCT?.title,
                content: FIND_PRODUCT?.descr,
                price: _productPrice(FIND_PRODUCT?.itemPricing, 'oneTime', row?.isEligibleForAutoOrder),
            })
        }
    });

    return (
        <HomeFirstSectionCss>
            <div className="banner-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner-text">
                                {/* <h1>Winner of the 2022 International ALLE award for Best Anti-Aging Well-Aging Finished Product</h1> */}
                                <h1>Patent Pending Glucose Management</h1>
                                <div className="">
                                    {/* {(CUSTOMER_TYPE?.type != 3) &&   */}
                                    <a href={`/${storeAssigned()}/shop/products/1096/insu_less`} className="btn btn-get-stareted started-btn">Get Started</a>
                                    {/* } */}
                                </div>

                            </div>
                            <div className="banner-product-now">
                                {/* <div className="banner-prd1"><img src={require("../assets/banner-product-min.png")} /></div> */}
                                <div className="banner-prd1"><img src={require("../assets/InsuLess-product-image-png-min-new.png")} /></div>
                                <div className="banner-logo"><img src={require("../assets/banner-logo.png")} /></div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="best-seller-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="best-seller-text">
                                <h2>Best Sellers</h2>
                                <h3>A homeopathic alternative to health and wellness</h3>
                                <div className="owl-carousel owl-theme" id="best-seller">
                                    {_.map(FIRST_THREE_PRODUCT, (row, index) => (
                                        <div className="item" key={"product" + index}>
                                            {row?.image ?
                                                <>
                                                    <div className="prd1">
                                                        <img src={row?.image || ""} alt="product" style={{ width: "100%" }} />
                                                    </div>
                                                    <div className="prd-text">
                                                        <h3>{row?.heading}</h3>
                                                        <p>{row?.content}</p>
                                                        <div className="start-price">
                                                            <div className="price">
                                                                <h3>Starting at ${isNaN(row?.price) ? 0 : row?.price}</h3>
                                                            </div>
                                                            <div className="show-all">
                                                                <a href={`/${storeAssigned()}/shop/Products/${row?.id}/${_.lowerCase(row?.heading)?.replaceAll(/ /g, '_')}`} className="btn btn-show">Shop Now</a>                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <CircularProgress />
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="we-unique-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="unique-text">
                                <h2>We are unique!</h2>
                                <div className="unique-logo">
                                    {_.map(Unique_SECTION, ({ image, para }, index) => (
                                        <div className="logo-unique" key={"unique" + index}>
                                            <div className="logo1">
                                                <img src={image} />
                                            </div>
                                            <p>{para}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </HomeFirstSectionCss >

    )
}
export default HomeFirstSection;